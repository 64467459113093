import { Routes } from '@angular/router';
import { signInGuard } from './core/guards/sign-in.guard';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { InternalErrorPageComponent } from './pages/internal-error-page/internal-error-page.component';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/routes').then((r) => r.AuthRoutes),
  },
  {
    path: 'app',
    canActivate: [signInGuard],
    component: AppLayoutComponent,
    children: [
      {
        path: 'admin',
        canActivate: [signInGuard],
        loadChildren: () => import('./admin/routes').then((r) => r.AdminRoutes),
      },
      {
        path: 'projects',
        canActivate: [signInGuard],
        loadChildren: () =>
          import('./routes/projects-routes').then((r) => r.ProjectRoutes),
      },
      { path: '', redirectTo: 'projects', pathMatch: 'full' },
    ],
  },
  { path: '500', component: InternalErrorPageComponent },
  { path: '', redirectTo: 'app', pathMatch: 'full' },
];
