<!-- <app-menu>
  <app-menu-trigger [template]="triggerTmpl"></app-menu-trigger>
  <app-menu-content [headerTmpl]="headerTmpl" [footerTmpl]="footerTmpl">
    @for (project of projects; track $index) {
    <app-menu-item (click)="onProjectChange(project._id)">
      <img menuItemIcon [src]="project.photo" width="32px" class="img-block" />

      {{ project.name }}
    </app-menu-item>
    }
  </app-menu-content>
</app-menu> -->

<div
  class="toolbar-projects"
  *ngIf="selectedProject"
  [appDropdown]="projectsDropdownTmpl">
  <div class="toolbar-projects-photo">
    <img [src]="selectedProject.photo" class="img-block" />
  </div>
  <div class="toolbar-projects-name">{{ selectedProject.name }}</div>
</div>

<ng-container
  *ngIf="!projects.length"
  [ngTemplateOutlet]="footerTmpl"></ng-container>

<ng-template #triggerTmpl>
  <div class="toolbar-projects" *ngIf="selectedProject">
    <div class="toolbar-projects-photo">
      <img [src]="selectedProject.photo" class="img-block" />
    </div>
    <div class="toolbar-projects-name">{{ selectedProject.name }}</div>
  </div>
</ng-template>

<ng-template #footerTmpl>
  <app-menu-item (click)="onProjectCreate()">
    <div menuItemIcon class="icon icon-plus icon-grey-300"></div>
    Add project</app-menu-item
  >
</ng-template>

<ng-template #headerTmpl>
  <app-menu-item [link]="['/app', 'projects', selectedProjectId, 'team']">
    <div menuItemIcon class="icon icon-user icon-grey-300"></div>
    Team</app-menu-item
  >
  <app-menu-item>
    <div menuItemIcon class="icon icon-star icon-grey-300"></div>
    Subscription</app-menu-item
  >
  <app-menu-item [link]="['/app', 'projects', selectedProjectId, 'settings']">
    <div menuItemIcon class="icon icon-gear icon-grey-300"></div>
    Settings</app-menu-item
  >
</ng-template>

<app-dropdown #projectsDropdownTmpl>
  <div class="dropdown">
    <div class="dropdown-list">
      <a
        [routerLink]="['/app', 'projects', selectedProjectId, 'team']"
        class="dropdown-list-item">
        <div class="dropdown-list-item-icon">
          <div class="icon icon-user icon-grey-300"></div>
        </div>
        Team</a
      >
      <!-- <a
        [routerLink]="['/app', 'projects', selectedProjectId, 'team']"
        class="dropdown-item">
        <div menuItemIcon class="icon icon-start icon-grey-300"></div>
        Subscription</a
      > -->
      <a
        [routerLink]="['/app', 'projects', selectedProjectId, 'settings']"
        class="dropdown-list-item">
        <div class="dropdown-list-item-icon">
          <div class="icon icon-gear icon-grey-300"></div>
        </div>
        Settings</a
      >
    </div>
    <div class="dropdown-list">
      <a
        *ngFor="let project of projects"
        (click)="onProjectChange(project._id)"
        class="dropdown-list-item">
        <div class="dropdown-list-item-icon">
          <img [src]="project.photo" class="img-block" />
        </div>
        {{ project.name }}
      </a>
    </div>

    <div class="dropdown-list">
      <a (click)="onProjectCreate()" class="dropdown-list-item">
        <div class="dropdown-list-item-icon">
          <div menuItemIcon class="icon icon-plus icon-grey-300"></div>
        </div>
        Add project
      </a>
    </div>
  </div>
</app-dropdown>
