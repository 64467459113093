import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Observable, Subscription, merge } from 'rxjs';
import { Dropdown } from './dropdown';

@Directive({
  selector: '[appDropdown]',
  standalone: true,
  host: {
    '(click)': 'toggle()',
  },
})
export class DropdownDirective implements OnDestroy {
  @Input() appDropdown!: Dropdown;

  private _overlayRef!: OverlayRef;
  private _isOpen = false;
  private _close$: Subscription = Subscription.EMPTY;

  constructor(
    private _overlay: Overlay,
    private _elementRef: ElementRef<HTMLElement>,
    private _viewContainerRef: ViewContainerRef
  ) {}

  toggle() {
    this._isOpen ? this.close() : this.open();
  }

  open() {
    this._isOpen = true;
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this._overlay.scrollStrategies.close(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._elementRef)
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetY: 8,
          },
        ]),
    });
    const templatePortal = new TemplatePortal(
      this.appDropdown.templateRef,
      this._viewContainerRef
    );
    this._overlayRef.attach(templatePortal);

    this._close$ = this.closeActions().subscribe(() => this.close());
  }

  close() {
    if (!this._overlayRef || !this._isOpen) {
      return;
    }
    this._close$.unsubscribe();
    this._isOpen = false;
    this._overlayRef.detach();
  }

  ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  private closeActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this._overlayRef.backdropClick();
    const detachment$ = this._overlayRef.detachments();

    return merge(backdropClick$, detachment$, this.appDropdown.closed);
  }
}
