import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ErrorsService } from '../services/errors.service';
import { catchError, throwError } from 'rxjs';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const errorsService = inject(ErrorsService);
  errorsService.setError(null);
  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      const { error, status } = err;

      if (status === 422) {
        errorsService.setErrors(error.errors);
      }
      if ([400].includes(status)) {
        errorsService.setError(error);
      }
      return throwError(() => error);
    })
  );
};
