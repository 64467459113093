import { Component } from '@angular/core';

@Component({
  selector: 'app-internal-error-page',
  standalone: true,
  imports: [],
  templateUrl: './internal-error-page.component.html',
  styleUrl: './internal-error-page.component.scss'
})
export class InternalErrorPageComponent {

}
