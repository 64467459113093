import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownModule } from '@app/components/dropdown/dropdown.module';
import { MenuModule } from '@app/components/menu/menu.module';
import { DialogService } from '@app/core/services/dialog.service';
import { IProject } from '@app/models/project';
import { ProjectsService } from '@app/services/projects.service';
import { FormControlsModule } from '@app/shared/form-controls/form-controls.module';
import { SharedModule } from '@app/shared/shared.module';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-toolbar-projects',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormControlsModule,
    MenuModule,
    DropdownModule,
  ],
  templateUrl: './toolbar-projects.component.html',
  styleUrl: './toolbar-projects.component.scss',
})
export class ToolbarProjectsComponent implements OnDestroy {
  private _destroy$: Subject<void> = new Subject();
  public selectedProject!: IProject;
  constructor(
    private _projectsService: ProjectsService,
    private _dialogService: DialogService,
    private _router: Router,
    private _cdr: ChangeDetectorRef
  ) {
    this._projectsService.selectedProject$
      .pipe(takeUntil(this._destroy$))
      .subscribe((selectedProject) => {
        this.selectedProject = selectedProject;
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public get projects() {
    return this._projectsService.projects;
  }

  public get selectedProjectId(): null | number {
    return this.selectedProject ? this.selectedProject._id : null;
  }

  public onProjectChange(projectId: number) {
    this._projectsService.changeProject(projectId);
    this._router.navigate(['/app', 'projects', projectId]);
  }

  public onProjectCreate() {
    this._projectsService
      .onCreate()
      .closed.pipe(filter((result) => !!result))
      .subscribe((project) => {
        this._projectsService.getUserProjects();
        this._projectsService.changeProject((<IProject>project)._id);
        this._router.navigate(['/app', 'projects', project?._id]);
      });
  }
}
