import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ProfileService } from '@app/core/services/profile.service';

@Component({
  selector: 'app-toolbar-profile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toolbar-profile.component.html',
  styleUrl: './toolbar-profile.component.scss',
})
export class ToolbarProfileComponent {
  constructor(private _profileService: ProfileService) {}

  public get profile$() {
    return this._profileService.profile$;
  }
}
