import { Observable, catchError, forkJoin, of, tap } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { ProfileService } from './core/services/profile.service';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export function initializeApp(
  authService: AuthService,
  profileService: ProfileService
) {
  const router = inject(Router);
  const requests: Record<string, Observable<unknown>> = {};

  if (authService.getToken()) {
    requests['validate'] = authService.validate().pipe(
      tap((res) => {
        if (res.success) {
          profileService.load().subscribe();
        }
      }),
      catchError((error) => {
        router.navigate(['/auth', 'sign-in'], { queryParamsHandling: 'merge' });
        return of(error);
      })
    );
  }

  return () => forkJoin(requests);
}
