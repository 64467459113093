<div class="layout">
  <div class="layout-toolbar">
    <div class="toolbar">
      <a [routerLink]="['/app']" class="toolbar-logo">
        <img src="/assets/images/logo.svg" width="120px" class="img-block"
      /></a>
      <div class="toolbar-menu" *ngIf="selectedProjectId">
        <a
          [routerLink]="['/app', 'projects', selectedProjectId, 'events']"
          class="toolbar-menu-item"
          routerLinkActive="toolbar-menu-item-active">
          <div class="toolbar-menu-item-icon">
            <div class="icon icon-event"></div>
          </div>
          <div class="toolbar-menu-item-text">Events</div> </a
        ><a
          [routerLink]="['/app', 'projects', selectedProjectId, 'properties']"
          class="toolbar-menu-item"
          routerLinkActive="toolbar-menu-item-active">
          <div class="toolbar-menu-item-icon">
            <div class="icon icon-property"></div>
          </div>
          <div class="toolbar-menu-item-text">Properties</div></a
        >
        <a
          [routerLink]="['/app', 'projects', selectedProjectId, 'screens']"
          class="toolbar-menu-item"
          routerLinkActive="toolbar-menu-item-active">
          <div class="toolbar-menu-item-icon">
            <div class="icon icon-screen"></div>
          </div>
          <div class="toolbar-menu-item-text">Screens</div></a
        >
        <a
          [routerLink]="['/app', 'projects', selectedProjectId, 'tags']"
          class="toolbar-menu-item"
          routerLinkActive="toolbar-menu-item-active">
          <div class="toolbar-menu-item-icon">
            <div class="icon icon-tag"></div>
          </div>
          <div class="toolbar-menu-item-text">Tags</div></a
        >
      </div>
      <div class="toolbar-space"></div>
      <app-toolbar-projects></app-toolbar-projects>
      <app-toolbar-profile></app-toolbar-profile>
    </div>
  </div>
  <div class="layout-content">
    <ng-scrollbar>
      <router-outlet></router-outlet>
    </ng-scrollbar>
  </div>
</div>
