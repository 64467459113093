import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { IProject } from '@app/models/project';
import { ProjectsService } from '@app/services/projects.service';
import { ToolbarProfileComponent } from '@app/shared/components/toolbar-profile/toolbar-profile.component';
import { ToolbarProjectsComponent } from '@app/shared/components/toolbar-projects/toolbar-projects.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
  selector: 'app-app-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    NgScrollbarModule,
    RouterLink,
    RouterLinkActive,
    CommonModule,
    SharedModule,
    ToolbarProfileComponent,
    ToolbarProjectsComponent,
  ],
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss',
})
export class AppLayoutComponent {
  public selectedProject!: IProject;
  constructor(private _projectsService: ProjectsService) {
    this._projectsService.selectedProject$.subscribe((project) => {
      this.selectedProject = project;
    });
  }

  public get selectedProjectId() {
    return this.selectedProject ? this.selectedProject._id : null;
  }
}
