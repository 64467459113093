import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DropdownDirective } from './dropdown.directive';

@NgModule({
  declarations: [],
  imports: [CommonModule, DropdownComponent, DropdownDirective],
  exports: [DropdownComponent, DropdownDirective],
})
export class DropdownModule {}
